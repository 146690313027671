@tailwind base;
@tailwind components;
@tailwind utilities;

.tooltip {
  @apply invisible absolute;
}

.has-tooltip:active .tooltip {
  @apply visible z-50;
}
